<template>
  <div style=" font-size:12px; text-align:right;">
    <!-- 技术支持电话:<b style="font-size:15px;">13166668753</b> (微信同步) -->
  </div>
</template>

<script>
export default {
  name: "FooterBar",
  props: {
    msg: String,
  },
};
</script>

<style scoped>
</style>
